import { DateTimeFormatEnum } from '@shared/enums/common.enum';
import dayjs from 'dayjs';

export const getDate = (date: string) => (date ? new Date(date) : '');
export const getDayJsFromDate = (date: Date) => (date ? dayjs(date) : '');

export const getDateOfISOWeek = (
  weekDate: string,
  format: DateTimeFormatEnum = DateTimeFormatEnum.DD_MM_YYYY
) => {
  const weekDateSplit = weekDate.split('-W');
  const y = +weekDateSplit[0];
  const w = +weekDateSplit[1];
  const simple = new Date(y, 0, 1 + (w - 1) * 7);
  const dow = simple.getDay();
  const ISOweekStart = simple;
  if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return dayjs(ISOweekStart).format(format).toString();
};

export const convertDateWithFormat = (
  date: string | undefined,
  format: string
) => {
  if (!date) return '';
  return dayjs(date).format(format);
};

export const convertToGmt = (dateTime: string) =>
  dayjs(
    new Date(dateTime).toLocaleString('en-US', {
      timeZone: 'Etc/GMT',
    })
  );
