export enum NotificationStatusEnum {
  PENDING = 'PENDING',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
}

export enum NotificationType {
  EMAIL = 'EMAIL',
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
}
