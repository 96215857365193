import { Languages } from "@shared/enums/language.enum";

export const languageOptions = [
    {
        value: Languages.EN,
        label: 'English'
    },
    {
        value: Languages.FR,
        label: 'French'
    },
    {
        value: Languages.ES,
        label: 'Spain'
    },
    {
        value: Languages.AR,
        label: 'Arabic'
    },
    {
        value: Languages.DE,
        label: 'German'
    },
    {
        value: Languages.HI,
        label: 'Hindi'
    },
    {
        value: Languages.ZH,
        label: 'Simplified Chinese'
    },
    {
        value: Languages.PT,
        label: 'Portuguese'
    },
    {
        value: Languages.VI,
        label: 'Vietnamese'
    },
    {
        value: Languages.TH,
        label: 'Thai'
    },
    {
        value: Languages.ID,
        label: 'Indonesian'
    },
    {
        value: Languages.JA,
        label: 'Japanese'
    },
    {
        value: Languages.KO,
        label: 'Korean'
    },
    {
        value: Languages.RU,
        label: 'Russian'
    },
]