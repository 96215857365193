<div class="flex flex-column" [ngClass]="wrapperClass">
  <div
    [ngClass]="isMobileView ? 'flex-column' : ' align-items-center'"
    class="flex justify-content-between gap-2"
  >
    <h6 class="w-2 m-0">To*</h6>
    <div
      [ngClass]="isMobileView ? 'flex-column gap-2' : 'gap-4'"
      class="flex {{ optionContainerClass }}"
    >
      <div
        [ngClass]="isMobileView ? 'w-full' : 'w-2'"
        *ngFor="let option of userGroupOptions; let i = index"
      >
        <p-radioButton
          value="{{ option.value }}"
          [formControl]="control"
          inputId="ug-{{ i }}"
        ></p-radioButton>
        <label for="ug-{{ i }}" class="ml-2 cursor-pointer">{{
          option.label
        }}</label>
      </div>
    </div>
  </div>
  <div
    *ngIf="isSendToSpecificUsers; else exceptEmailsElt"
    [ngClass]="isMobileView ? 'flex-column' : ' align-items-center'"
    class="flex gap-2 p-fluid"
  >
    <h6 class="w-2 m-0">Specific users*</h6>
    <p-chips class="w-full" [formControl]="specificEmailsControl"></p-chips>
  </div>
  <ng-template #exceptEmailsElt>
    <div
      *ngIf="exceptEmailsControl"
      [ngClass]="isMobileView ? 'flex-column' : ' align-items-center'"
      class="flex gap-2 p-fluid"
    >
      <h6 class="w-2 m-0">Except users</h6>
      <p-chips class="w-full" [formControl]="exceptEmailsControl"></p-chips>
    </div>
  </ng-template>
</div>
