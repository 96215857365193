import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseDestroyable } from '@shared/directives/base-destroyable.directive';
import { LocalStorageKey } from '@shared/enums/local-storage.enum';
import { ErrorHandlerService } from '@shared/services/error-handler.service';
import {
  Observable,
  Subject,
  catchError,
  takeUntil,
  throwError
} from 'rxjs';

@Injectable()
export class ErrorInterceptor extends BaseDestroyable implements HttpInterceptor {
  private errorSubject = new Subject<HttpErrorResponse>();
  private error$ = this.errorSubject
    .asObservable();
  constructor(
    private readonly errorHandler: ErrorHandlerService,
    private readonly router: Router
  ) {
    super();
    this.error$.pipe(takeUntil(this.destroy$)).subscribe((err) => this.errorHandler.handle(err));
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          localStorage.removeItem(LocalStorageKey.TOKEN);
          this.router.navigate(['login']);
        }
        this.errorSubject.next(err);
        return throwError(err);
      })
    );
  }
}
