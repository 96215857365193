import { PlatformEnum } from '@shared/enums/platform.enum';

export const platformOptions = [
  {
    value: JSON.stringify([
      PlatformEnum.ANDROID,
      PlatformEnum.IPHONE,
      PlatformEnum.IPAD,
    ]),
    label: 'All',
  },
  {
    value: JSON.stringify([PlatformEnum.ANDROID]),
    label: 'Android',
  },
  {
    value: JSON.stringify([PlatformEnum.IPHONE, PlatformEnum.IPAD]),
    label: 'Ios',
  },
];
