export enum OrderEnum {
  ASC = "ASC",
  DESC = "DESC",
}

export enum DateTimeFormatEnum {
  DD_MM_YYYY = "DD/MM/YYYY",
  MM_YYYY = "MM/YYYY",
  YYYY = "YYYY",
  YYYY_MM_dd_DASH = "YYYY-MM-DD",
  YYYY_MM_DD_HH_mm_ss_DASH = "YYYY-MM-DD HH:mm:ss",
  DD_MM_YYYY_HH_mm = "DD/MM/YYYY HH:mm",
  dd_MM_YYYY_HH_mm = "dd/MM/YYYY HH:mm",
  dd_MM_YYYY = "dd/MM/YYYY",
  dd_mm_yy = "dd/mm/yy",
}

export enum DateRangeInputView {
  DATE = "date",
  MONTH = "month",
  YEAR = "year",
}

export enum StatisticsBy {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
}
