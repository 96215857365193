import { Inject, Injectable } from '@angular/core';
import { IChangeUserPasswordRequest } from '@shared/models/change-user-password.model';
import { APP_CONFIG, AppConfig } from 'app.config';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({ providedIn: 'root' })
export class PasswordHttpService {
  private resource: string;

  constructor(
    private http: HttpService,
    @Inject(APP_CONFIG)
    private config: AppConfig
  ) {
    this.resource = this.config.apiHost;
  }

  public change(params: IChangeUserPasswordRequest): Observable<void> {
    return this.http.post<void>(`${this.resource}/passwords/change`, params);
  }
}
