<div class="custom-input">
  <div class="input-container">
    <input
      pInputText
      [formControl]="control"
      [type]="type"
      [placeholder]="placeholder"
      [class]="class"
    />
  </div>
  <div class="error-messages">
    <small *ngIf="hasError(control, 'required')" class="color-error">
      {{ requiredErrorMessage }}</small
    >
    <small
      *ngIf="hasError(control, 'email') || hasError(control, 'pattern')"
      class="color-error"
      >Wrong format.</small
    >
  </div>
</div>
