import { IChangeUserPasswordRequest } from '@shared/models/change-user-password.model';
import { BasicParams } from '@shared/models/http-request.model';

export class GetUsers {
  static readonly type = '[User] Get users';
  constructor(public params?: BasicParams) {}
}

export class ChangeUserPassword {
  static readonly type = '[User] Change user password';
  constructor(public params: IChangeUserPasswordRequest) {}
}
