import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { UserHttpService } from '@shared/http/user-http.service';
import { Observable, tap } from 'rxjs';
import { ChangeUserPassword, GetUsers } from '../actions/user.action';
import { IUserStateModel } from '../state-models/user-state.model';
import { IHttpResponse } from '@shared/models/http-response.model';
import { User } from '@shared/models/user.model';
import { MessageService } from 'primeng/api';
import { PasswordHttpService } from '@shared/http/password-http.service';

@State<IUserStateModel>({
  name: 'userState',
  defaults: {
    users: {
      data: [],
    },
  },
})
@Injectable()
export class UserState {
  constructor(
    private userHttpService: UserHttpService,
    private passwordHttpService: PasswordHttpService,
    private messageService: MessageService
  ) {}

  @Action(GetUsers) getUsers(
    ctx: StateContext<IUserStateModel>,
    { params }: GetUsers
  ): Observable<IHttpResponse<User[]>> {
    return this.userHttpService.getUsers(params).pipe(
      tap((users) => {
        const state = ctx.getState();
        ctx.patchState({
          ...state,
          users,
        });
      })
    );
  }

  @Action(ChangeUserPassword) changeUserPassword(
    ctx: StateContext<IUserStateModel>,
    { params }: ChangeUserPassword
  ): Observable<void> {
    return this.passwordHttpService.change(params).pipe(
      tap({
        complete: () =>
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: "User's password has been changed successfully.",
          }),
      })
    );
  }
}
