import { Inject, Injectable } from '@angular/core';
import { NotificationType } from '@shared/enums/notification.enum';
import { isEmailNotificationType } from '@shared/helpers/notification.helper';
import { Notification } from '@shared/models/notification.model';
import { APP_CONFIG, AppConfig } from 'app.config';
import { Observable } from 'rxjs';
import { IHttpResponse } from '../models/http-response.model';
import { HttpService } from './http.service';

@Injectable({ providedIn: 'root' })
export class NotificationHttpService {
  private resource: string;

  constructor(
    private http: HttpService,
    @Inject(APP_CONFIG)
    private config: AppConfig
  ) {
    this.resource = this.config.apiHost;
  }

  public getNotifications(
    notificationType = NotificationType.EMAIL
  ): Observable<Notification[]> {
    return this.http.get<Notification[]>(
      `${this.resource}/notifications/${
        isEmailNotificationType(notificationType) ? 'emails' : 'pushes'
      }`
    );
  }

  public getNotification(id: string): Observable<Notification> {
    return this.http.get<Notification>(`${this.resource}/notifications/${id}`);
  }

  public create(data: Notification): Observable<Notification> {
    return this.http.post<Notification>(`${this.resource}/notifications`, data);
  }

  public update(data: Notification): Observable<IHttpResponse<Notification>> {
    return this.http.patch<IHttpResponse<Notification>>(
      `${this.resource}/notifications/${data.id}`,
      data
    );
  }

  public delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.resource}/notifications/${id}`);
  }
}
