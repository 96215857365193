import { languageOptions } from '@shared/constants/language.const';
import { notificationStatusOptions } from '@shared/constants/notification.const';
import { platformOptions } from '@shared/constants/platform.const';
import { productOptions } from '@shared/constants/product-options.const';
import { subscriptionPlanOptions } from '@shared/constants/subscription-plan-options.const';
import { userGroupOptions } from '@shared/constants/user.const';
import { Languages } from '@shared/enums/language.enum';
import { NotificationStatusEnum } from '@shared/enums/notification.enum';
import { PlatformEnum } from '@shared/enums/platform.enum';
import { ProductEnum } from '@shared/enums/product.enum';
import { SubscriptionPlan } from '@shared/enums/subscription-plan.enum';
import { UserGroupEnum } from '@shared/enums/user.enum';
import { AnnouncementLocale } from '@shared/models/announcement.model';
import { NotificationLocale } from '@shared/models/notification.model';

export const titleCase = (str: string) =>
  str
    .toLowerCase()
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const getNotificationStatusLabel = (
  notificationStatusValue: NotificationStatusEnum
) =>
  notificationStatusOptions.find((ns) => ns.value === notificationStatusValue)
    ?.label;

export const getNotificationSendToLabel = (to: UserGroupEnum) =>
  userGroupOptions.find((ns) => ns.value === to)?.label;

export const getProductLabel = (product: ProductEnum) =>
  productOptions.find((p) => p.value === product)?.label;

export const getLocaleName = (locale: Languages): string =>
  languageOptions.find((lang) => lang.value === locale)?.label || '';

export const getSubscriptionPlanLabel = (subscriptionPlan: SubscriptionPlan) =>
  subscriptionPlanOptions.find((p) => p.value === subscriptionPlan)?.label;

export const getNotificationPlatformLabel = (platforms: PlatformEnum[]) =>
  platformOptions.find((option) => option.value === JSON.stringify(platforms))
    ?.label || 'All';

export const stringIsInclude = (baseString: string, targetString: string) =>
  baseString.toLowerCase().includes(targetString.toLowerCase());

export const getLocales = (
  locales: NotificationLocale[] | AnnouncementLocale[]
): string => {
  if (locales.length === languageOptions.length) {
    return 'All';
  }
  return (
    locales.map((locale) => getLocaleName(locale.langCode)) || []
  ).toString();
};

export const generateRandomPassword = (length = 6): string => {
  const charset =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$%&*!';
  let password = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }

  if (password.length < 6) {
    password += generateRandomPassword(6 - password.length);
  }

  return password;
}
