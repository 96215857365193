import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { DiscountHttpService } from '@shared/http/discount-http.service';
import { Discount } from '@shared/models/discount.model';
import { Observable, tap } from 'rxjs';
import {
  FilteringAndSearchingDiscounts,
  GetDiscounts,
} from '../actions/discount.action';
import { IDiscountStateModel } from '../state-models/discount-state.model';
import { filteringAndSearchingWithDateRangeObject } from '@shared/helpers/filtering-and-searching.helper';

@State<IDiscountStateModel>({
  name: 'discountState',
  defaults: {
    discounts: [],
    baseDiscounts: [],
  },
})
@Injectable()
export class DiscountState {
  constructor(private discountHttpService: DiscountHttpService) {}

  @Action(GetDiscounts) getDiscounts(
    ctx: StateContext<IDiscountStateModel>
  ): Observable<Discount[]> {
    return this.discountHttpService.getDiscounts().pipe(
      tap((baseDiscounts) => {
        const state = ctx.getState();
        ctx.patchState({
          ...state,
          baseDiscounts: baseDiscounts.map(
            (discount) => new Discount(discount)
          ),
        });
      })
    );
  }
  @Action(FilteringAndSearchingDiscounts) filteringAndSearchingDiscounts(
    ctx: StateContext<IDiscountStateModel>,
    { params }: FilteringAndSearchingDiscounts
  ): Discount[] {
    const state = ctx.getState();
    const discounts = filteringAndSearchingWithDateRangeObject(state.baseDiscounts, params);
    ctx.patchState({
      ...state,
      discounts,
    });
    return discounts;
  }
}
