import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(
    private messageService: MessageService
  ) {}
  public handle(errorResponse: HttpErrorResponse) {
    const error = errorResponse?.error?.message;

    let errorMessage;

    if (error) {
      errorMessage = error.includes('_')
        ? `ERROR_MESSAGES.${error?.toUpperCase()}`
        : error;
    } else {
      errorMessage = 'Unknown error';
    }

    if (errorResponse.status === 400) {
      this.showNotify(errorMessage);
      return;
    }

    if (errorResponse.status === 404) {
      this.showNotify(errorMessage);
      return;
    }

    if (errorResponse.status === 403) {
      this.showNotify(errorMessage);
      return;
    }

    errorMessage =
      errorResponse?.error?.error || errorResponse.message;
    this.showNotify(errorMessage);
  }

  private showNotify(message: string): void {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: message });
  }
}
