import {
  AbstractControl,
  UntypedFormControl,
  ValidationErrors,
} from "@angular/forms";

export const hasValidationError = (
  control: UntypedFormControl,
  errorName: string
) => (control.dirty || control.touched) && control.hasError(errorName);

export const dateRangeRequiredToDate =
  () =>
  (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    return !value || !value[1] ? { required: true } : null;
  };
