import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { userGroupOptions } from '@shared/constants/user.const';
import { UserGroupEnum } from '@shared/enums/user.enum';
import { isMobileView } from '@shared/helpers/mobile.helper';

@Component({
  selector: 'app-user-group-picker',
  templateUrl: './user-group-picker.component.html',
  styleUrls: ['./user-group-picker.component.scss']
})
export class UserGroupPickerComponent {
  @Input()
  public control!: UntypedFormControl;
  @Input()
  public specificEmailsControl!: UntypedFormControl;
  @Input()
  public exceptEmailsControl!: UntypedFormControl;
  @Input()
  public wrapperClass = '';
  @Input()
  public optionContainerClass = '';
  public userGroupOptions = userGroupOptions;
  public isMobileView = isMobileView;

  public get isSendToSpecificUsers(): boolean {
    return this.control?.value === UserGroupEnum.SPECIFIC;
  }

}
