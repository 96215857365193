import { NgModule } from "@angular/core";

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { NgxsModule } from '@ngxs/store';
import { LoadingState } from '@shared/ngxs-store/states/loading.state';
import { MessageService } from 'primeng/api';
import { UserState } from '@shared/ngxs-store/states/user.state';
import { NotificationState } from '@shared/ngxs-store/states/notification.state';
import { RouteState } from '@shared/ngxs-store/states/route.state';
import { StatisticState } from '@shared/ngxs-store/states/statistic.state';
import { MainLayoutComponent } from "./layout/components/main-layout/main-layout.component";
import { MenuItemComponent } from "./layout/components/menu-item/menu-item.component";
import { MenuComponent } from "./layout/components/menu/menu.component";
import { SidebarComponent } from "./layout/components/sidebar/sidebar.component";
import { AnnouncementState } from "@shared/ngxs-store/states/announcement.state";
import { DiscountState } from "@shared/ngxs-store/states/discount.state";

const ngxsStates = [
  LoadingState,
  UserState,
  NotificationState,
  RouteState,
  StatisticState,
  AnnouncementState,
  DiscountState,
];
@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    MenuItemComponent,
    MainLayoutComponent,
    MenuComponent,
  ],
  imports: [
    AppRoutingModule,
    SharedModule,
    CoreModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot(ngxsStates, {
      developmentMode: false,
    }),
  ],
  providers: [MessageService],
  bootstrap: [AppComponent],
})
export class AppModule {}
