export enum RedirectToScreen {
    PREMIUM = 'Premium',
    TODO = 'Todo',
    SHOPPING = 'Shopping',
    REWARD = 'Reward',
    CALENDAR = 'Calendar',
    HOME = 'Home',
    SETTINGS = 'Settings',
}

export enum ScreenSize {
    mobile = 540,
    smallMobile = 360,
  }
  