import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { SetLoading } from '@shared/ngxs-store/actions/loading.action';
import { finalize } from 'rxjs/operators';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private totalRequests = 0;

  constructor(private readonly store: Store) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    this.totalRequests++;
    this.store.dispatch(new SetLoading(true));
    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
          this.store.dispatch(new SetLoading(false));
        }
      })
    );
  }
}
