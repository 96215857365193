import { FilteringSearchingParams } from '@shared/models/http-request.model';

export class GetAnnouncements {
  static readonly type = '[Announcement] Get announcements';
}

export class FilteringAndSearchingAnnouncements {
  static readonly type = '[Announcement] Filtering and searching announcements';
  constructor(public params?: FilteringSearchingParams) {}
}
