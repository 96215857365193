<div *ngIf="languageOptions" class="locale-multi-select relative">
  <div class="w-full">
    <p-multiSelect
      styleClass="w-full"
      [options]="languageOptions"
      [formControl]="localeKeysControl"
      optionValue="value"
      optionLabel="label"
      appendTo="body"
      [displaySelectedLabel]="!isSelectAllLocales"
      [defaultLabel]="isSelectAllLocales ? 'All' : ''"
      (onChange)="localeChangeEvent($event.itemValue, $event.value)"
    ></p-multiSelect>
  </div>

  <small
    *ngIf="hasError(localeKeysControl, 'required')"
    class="error-message absolute color-error"
    >Locale is required.</small
  >
</div>
