import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { languageOptions } from '@shared/constants/language.const';
import { Languages } from '@shared/enums/language.enum';
import { hasValidationError } from '@shared/utils/validation.utilts';

@Component({
  selector: 'app-locale-multi-select',
  templateUrl: './locale-multi-select.component.html',
})
export class LocaleMultiSelectComponent implements OnInit {
  @Input()
  public formArray!: UntypedFormArray;
  @Input()
  public initFormArrayControlFunction!: (initValue: {
    langCode: Languages;
  }) => void;
  @Output()
  public _onChange = new EventEmitter();
  public localeKeysControl = new UntypedFormControl('', Validators.required);

  public languageOptions = languageOptions;
  public hasError = hasValidationError;

  ngOnInit() {
    this.initLocaleKeys();
  }

  public get isSelectAllLocales(): boolean {
    return languageOptions?.length === this.localeKeysControl.value.length;
  }

  public localeChangeEvent(
    langCode: Languages,
    currentLocales: Languages[]
  ): void {
    const isToggleAll = !langCode;
    if (isToggleAll) {
      if (currentLocales.length) {
        languageOptions
          .map((language) => language.value)
          .forEach((langCode: Languages) =>
            this.formArray.push(this.initFormArrayControlFunction({ langCode }))
          );
      } else {
        this.formArray.clear();
      }
    } else {
      const isRemove = !currentLocales.some(
        (localeItem) => localeItem === langCode
      );
      if (isRemove) {
        const removeIndex = this.formArray.value.findIndex(
          (locale: { langCode: Languages }) => locale.langCode === langCode
        );
        this.formArray.removeAt(removeIndex);
      } else {
        this.formArray.push(this.initFormArrayControlFunction({ langCode }));
      }
    }
    this._onChange.emit(this.formArray.value);
  }

  private initLocaleKeys(): void {
    const localeKeys = this.formArray.value ? this.formArray.value.map((item: { langCode: Languages}) => item.langCode) : languageOptions.map(lang => lang.value);
    this.localeKeysControl.setValue(localeKeys);
  }
}
