import { Inject, Injectable } from "@angular/core";
import { APP_CONFIG, AppConfig } from "app.config";
import { Observable } from "rxjs";
import { BasicParams } from "../models/http-request.model";
import { IHttpResponse } from "../models/http-response.model";
import { HttpService } from './http.service';
import { Discount } from "@shared/models/discount.model";


@Injectable({ providedIn: 'root' })
export class DiscountHttpService {
  private resource: string;

  constructor(
    private http: HttpService,
    @Inject(APP_CONFIG)
    private config: AppConfig,
  ) {
    this.resource = this.config.apiHost;
  }

  public getDiscounts(params?: BasicParams): Observable<Discount[]> {
    return this.http
      .get<Discount[]>(`${this.resource}/discounts`, params);
  }

  public getDiscount(id: string): Observable<Discount> {
    return this.http
      .get<Discount>(`${this.resource}/discounts/${id}`);
  }

  public create(data: Discount): Observable<Discount> {
    return this.http
      .post<Discount>(`${this.resource}/discounts`, data);
  }

  public update(data: Discount): Observable<IHttpResponse<Discount>> {
    return this.http
      .patch<IHttpResponse<Discount>>(`${this.resource}/discounts/${data.id}`, data);
  }

  public delete(id: string): Observable<void> {
    return this.http
      .delete<void>(`${this.resource}/discounts/${id}`);
  }
}
