import { Inject, Injectable } from "@angular/core";
import { APP_CONFIG, AppConfig } from "app.config";
import { Observable } from "rxjs";
import { BasicParams } from "../models/http-request.model";
import { IHttpResponse } from "../models/http-response.model";
import { HttpService } from './http.service';
import { Announcement } from "@shared/models/announcement.model";


@Injectable({ providedIn: 'root' })
export class AnnouncementHttpService {
  private resource: string;

  constructor(
    private http: HttpService,
    @Inject(APP_CONFIG)
    private config: AppConfig,
  ) {
    this.resource = this.config.apiHost;
  }

  public getAnnouncements(params?: BasicParams): Observable<Announcement[]> {
    return this.http
      .get<Announcement[]>(`${this.resource}/announcements`, params);
  }

  public getAnnouncement(id: string): Observable<Announcement> {
    return this.http
      .get<Announcement>(`${this.resource}/announcements/${id}`);
  }

  public create(data: Announcement): Observable<Announcement> {
    return this.http
      .post<Announcement>(`${this.resource}/announcements`, data);
  }

  public update(data: Announcement): Observable<IHttpResponse<Announcement>> {
    return this.http
      .patch<IHttpResponse<Announcement>>(`${this.resource}/announcements/${data.id}`, data);
  }

  public delete(id: string): Observable<void> {
    return this.http
      .delete<void>(`${this.resource}/announcements/${id}`);
  }
}
