import { Inject, Injectable } from "@angular/core";
import { APP_CONFIG, AppConfig } from "app.config";
import { Observable } from "rxjs";
import { BasicParams } from "../models/http-request.model";
import { NewUsersStatisticResponse, StatisticResponse } from "../models/http-response.model";
import { HttpService } from './http.service';


@Injectable({ providedIn: 'root' })
export class StatisticHttpService {
  private resource: string;

  constructor(
    private http: HttpService,
    @Inject(APP_CONFIG)
    private config: AppConfig,
  ) {
    this.resource = this.config.apiHost;
  }

  public getStatistic(params?: BasicParams): Observable<StatisticResponse> {
    return this.http
      .get<StatisticResponse>(`${this.resource}/users/statistics`, params);
  }

  public getNewUsersStatistic(params?: BasicParams): Observable<NewUsersStatisticResponse> {
    return this.http
      .get<NewUsersStatisticResponse>(`${this.resource}/users/statistics/new-users-per-day`, params);
  }
}
