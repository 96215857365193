import { UserGroupEnum } from "@shared/enums/user.enum";

export const userGroupOptions = [
    {
        value: UserGroupEnum.ALL_USER,
        label: 'All user'
    },
    {
        value: UserGroupEnum.NORMAL_USER,
        label: 'Normal user'
    },
    {
        value: UserGroupEnum.PREMIUM_USER,
        label: 'Premium user'
    },
    {
        value: UserGroupEnum.SUPER_USER,
        label: 'Super user'
    },
    {
        value: UserGroupEnum.SPECIFIC,
        label: 'Specific user'
    },
]