import { Languages } from '@shared/enums/language.enum';
import { RedirectToScreen } from '@shared/enums/screen.enum';
import { Status } from '@shared/enums/status.enum';
import { UserGroupEnum } from '@shared/enums/user.enum';
import { getStatus } from '@shared/helpers/status.helper';
import { getLocales } from '@shared/helpers/string.helper';

export class Announcement {
  id: string;
  title: string;
  status: Status;
  to: UserGroupEnum;
  specificEmails: string[];
  enabled: boolean;
  startDateTime: string;
  endDateTime: string;
  locales: string;
  localeKeys?: string[];
  redirectToScreen: RedirectToScreen;
  announcementByLocales: AnnouncementLocale[];

  constructor(announcement: Announcement) {
    this.id = announcement.id;
    this.title = announcement.title;
    this.status = getStatus(
      announcement.startDateTime,
      announcement.endDateTime
    );
    this.to = announcement.to;
    this.specificEmails = announcement.specificEmails;
    this.enabled = announcement.enabled;
    this.startDateTime = announcement.startDateTime;
    this.endDateTime = announcement.endDateTime;
    this.announcementByLocales = announcement.announcementByLocales;
    this.redirectToScreen = announcement.redirectToScreen;
    this.locales = getLocales(announcement.announcementByLocales);
    this.localeKeys = announcement.announcementByLocales.map(
      (locale) => locale.langCode
    );
  }
}

export interface AnnouncementLocale {
  id: string;
  langCode: Languages;
  bannerUrl: string;
  textButton: string;
}
