export enum Languages {
    EN = 'en', // english
    FR = 'fr', // french
    ES = 'es', // Spain
    AR = 'ar', // Arabic
    DE = 'de', // German
    HI = 'hi', // Hindi
    ZH = 'zh', // Simplified Chinese
    PT = 'pt', // Portuguese
    VI = 'vi', // Vietnamese
    TH = 'th', // Thai
    ID = 'id', // Indonesian
    JA = 'ja', // Japanese
    KO = 'ko', // Korean
    RU = 'ru'  // Russian
}