import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { hasValidationError } from '@shared/utils/validation.utilts';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent {
  @Input()
  public control!: UntypedFormControl;
  @Input()
  public type = 'text';
  @Input()
  public placeholder = '';
  @Input()
  public class = '';
  @Input()
  public requiredErrorMessage = 'This field is required.';

  public hasError = hasValidationError;
}
