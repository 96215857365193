import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { appRouteConst } from './app-routing.const';
import { authRouteConst } from './modules/auth/auth-routing.const';
import { AuthGuardService } from './core/guards/auth.guard';
import { NonAuthGuardService } from './core/guards/non-auth.guard';
import { MainLayoutComponent } from './layout/components/main-layout/main-layout.component';
import { NotificationType } from '@shared/enums/notification.enum';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: appRouteConst.dashboard,
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: appRouteConst.user,
        loadChildren: () =>
          import('./modules/user-management/user-management.module').then(
            (m) => m.UserManagementModule
          ),
      },
      {
        path: appRouteConst.email,
        loadChildren: () =>
          import(
            './modules/notification-management/notification-management.module'
          ).then((m) => m.NotificationManagementModule),
        data: {
          notificationType: NotificationType.EMAIL,
        },
      },
      {
        path: appRouteConst.pushNotification,
        loadChildren: () =>
          import(
            './modules/notification-management/notification-management.module'
          ).then((m) => m.NotificationManagementModule),
        data: {
          notificationType: NotificationType.PUSH_NOTIFICATION,
        },
      },
      {
        path: appRouteConst.announcement,
        loadChildren: () =>
          import(
            './modules/announcement-management/announcement-management.module'
          ).then((m) => m.AnnouncementManagementModule),
      },
      {
        path: appRouteConst.discount,
        loadChildren: () =>
          import(
            './modules/discount-management/discount-management.module'
          ).then((m) => m.DiscountManagementModule),
      },
      {
        path: '',
        redirectTo: appRouteConst.dashboard,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: appRouteConst.auth,
    canActivate: [NonAuthGuardService],
    children: [
      {
        path: authRouteConst.login,
        loadChildren: () =>
          import('./modules/auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: '',
        redirectTo: authRouteConst.login,
        pathMatch: 'full',
      },
    ],
  },
  { path: appRouteConst.notFound, component: NotFoundComponent },
  { path: '**', redirectTo: `/${appRouteConst.notFound}` },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
