import { ProductEnum } from "@shared/enums/product.enum";

export const productOptions = [
    {
        value: ProductEnum.ANNUALLY,
        label: 'Annually'
    },
    {
        value: ProductEnum.MONTHLY,
        label: 'Monthly'
    },
]