import { FilteringSearchingParams } from '@shared/models/http-request.model';
import dayjs from 'dayjs';
import { stringIsInclude } from './string.helper';
import { UserGroupEnum } from '@shared/enums/user.enum';
import { Notification } from '@shared/models/notification.model';

export const filteringAndSearchingWithDateRangeObject = (
  baseArray: any[],
  params?: FilteringSearchingParams
) =>
  baseArray.filter(
    (item) =>
      (!params?.fromDate ||
        !dayjs(item.endDateTime).isBefore(dayjs(params.fromDate))) &&
      (!params?.toDate ||
        !(
          (dayjs(item.endDateTime).isAfter(dayjs(params.toDate).endOf('d')) &&
            dayjs(item.startDateTime).isAfter(
              dayjs(params.toDate).endOf('d')
            )) ||
          (dayjs(item.endDateTime).isBefore(dayjs(params.fromDate)) &&
            dayjs(item.startDateTime).isBefore(dayjs(params.fromDate)))
        )) &&
      basicFilteringAndSearching(item, params)
  );


export const filteringAndSearchingNoitfication = (
  baseArray: Notification[],
  params?: FilteringSearchingParams
) =>
  baseArray.filter(
    (item) =>
      (!params?.fromDate ||
        !dayjs(item.scheduledDateTime).isBefore(dayjs(params.fromDate))) &&
      (!params?.toDate ||
        !dayjs(item.scheduledDateTime).isAfter(dayjs(params.toDate).endOf('d'))) &&
      basicFilteringAndSearching(item, params)
  );

const basicFilteringAndSearching = (
  item: any,
  params?: FilteringSearchingParams
) =>
  (!params?.q || stringIsInclude(item.title || item.name, params.q)) &&
  (!params?.subscriptionPlan ||
    params.subscriptionPlan === item.subscriptionPlan) &&
  (!params?.to ||
    params.to === UserGroupEnum.ALL_USER ||
    params.to === item.to) &&
  (!params?.status || params.status === item.status);
