/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class HttpService {

  constructor(private http: HttpClient) { }

  removeEmptyParams(params: any): HttpParams {
    let httpParams = new HttpParams();

    Object.keys(params)
      .filter(key => params[key] !== undefined && params[key] !== null && params[key] !== '')
      .forEach(key => {
        httpParams = httpParams.set(key, params[key]);
      });

    return httpParams;
  }

  get<T>(url: string, params?: any): Observable<T> {
    const httpParams = params ? this.removeEmptyParams(params) : {};
    return this.http.get<T>(url, { params: httpParams });
  }

  post<T>(url: string, body: any, params?: any): Observable<T> {
    const httpParams = params ? this.removeEmptyParams(params) : {};
    return this.http.post<T>(url, body, { params: httpParams });
  }

  patch<T>(url: string, body: any, params?: any): Observable<T> {
    const httpParams = params ? this.removeEmptyParams(params) : {};
    return this.http.patch<T>(url, body, { params: httpParams });
  }

  delete<T>(url: string, params?: any): Observable<T> {
    const httpParams = params ? this.removeEmptyParams(params) : {};
    return this.http.delete<T>(url, httpParams);
  }

  // Add more HTTP methods as needed (e.g. put(), delete(), etc.)
}