import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { RemoveBeforeRoute, SetBeforeRoute } from '../actions/route.action';
import { IRouteStateModel } from '../state-models/route-state.model';

@State<IRouteStateModel>({
  name: 'routeState',
  defaults: {
    beforeRoute: '',
  },
})
@Injectable()
export class RouteState {

  @Action(SetBeforeRoute) setBeforeRoute(
    ctx: StateContext<IRouteStateModel>,
    { beforeRoute }: SetBeforeRoute
  ): void {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      beforeRoute,
    });
  }

  @Action(RemoveBeforeRoute) removeBeforeRoute(
    ctx: StateContext<IRouteStateModel>,
  ): void {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      beforeRoute: '',
    });
  }
}
