import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { appRouteConst } from 'src/app/app-routing.const';
import { authRouteConst } from 'src/app/modules/auth/auth-routing.const';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(
    private readonly auth: AuthService,
    private readonly router: Router
  ) {}

  public canActivate(): Observable<boolean> {
    if (!this.auth.isAuthenticated) {
      this.router.navigate([`/${appRouteConst.auth}/${authRouteConst.login}`]);
      return of(false);
    }
    return of(true);
  }
}
