import { SubscriptionPlan } from "@shared/enums/subscription-plan.enum";

export const subscriptionPlanOptions = [
    {
        value: SubscriptionPlan.MONTHLY,
        label: 'Monthly'
    },
    {
        value: SubscriptionPlan.ANNUALLY,
        label: 'Annually'
    },
    {
        value: SubscriptionPlan.ALL,
        label: 'All'
    },
]