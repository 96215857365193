import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { IStateModel } from '@shared/ngxs-store/state-models/state.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss']
})
export class LoadingOverlayComponent {
  constructor(private store: Store) {}

  public get isLoading$(): Observable<boolean> {
    return this.store.select((state: IStateModel) => state.loadingState.isLoading);
  }
}
