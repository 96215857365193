import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import {
  tabMenuItems,
  tabMenuItemsWithoutWeekFormat,
} from "@shared/constants/tab-menu.const";
import { DateTimeFormatEnum, StatisticsBy } from "@shared/enums/common.enum";
import {
  convertDateWithFormat,
  getDateOfISOWeek,
} from "@shared/helpers/date.helper";
import { StatisticHttpService } from "@shared/http/statistic-http.service";
import {
  NewUsersStatisticResponse,
  StatisticResponse,
} from "@shared/models/http-response.model";
import {
  NewUsersStatistic,
  Statistic,
  StatisticInfo,
} from "@shared/models/statistic.model";
import { TabMenuItem } from "@shared/models/tab-menu.model";
import { Observable, tap } from "rxjs";
import {
  GetNewUsersStatistic,
  GetStatistics,
} from "../actions/statistic.action";
import { IStatisticStateModel } from "../state-models/statistic-state.model";

const StatisticItemDefault: StatisticInfo = {
  date: "",
  totalAnnuallyPremiumUsers: 0,
  totalMonthlyPremiumUsers: 0,
  totalNormalUsers: 0,
  totalUsers: 0,
};

@State<IStatisticStateModel>({
  name: "statisticState",
  defaults: {
    statistics: {
      statistics: [],
      growthRate: 0,
      statisticInFirstDayOfPeriod: StatisticItemDefault,
      statisticInLastDayOfPeriod: StatisticItemDefault,
    },
    newUsersStatistic: {
      data: [],
      total: 0,
    },
  },
})
@Injectable()
export class StatisticState {
  private previousStatistic!: Statistic;
  constructor(private statisticHttpService: StatisticHttpService) {}

  @Action(GetStatistics) getStatistics(
    ctx: StateContext<IStatisticStateModel>,
    { params }: GetStatistics
  ): Observable<StatisticResponse> {
    return this.statisticHttpService.getStatistic(params).pipe(
      tap((statistics) => {
        const state = ctx.getState();
        const calculatedStatistic = this.calcStatistic(statistics);
        ctx.patchState({
          ...state,
          statistics: {
            ...calculatedStatistic,
            statistics: this.formatTimeByGroup(
              tabMenuItemsWithoutWeekFormat,
              params.statisticsBy,
              calculatedStatistic.statistics
            ),
          },
        });
      })
    );
  }

  @Action(GetNewUsersStatistic) getNewUsersStatistics(
    ctx: StateContext<IStatisticStateModel>,
    { params }: GetNewUsersStatistic
  ): Observable<NewUsersStatisticResponse> {
    return this.statisticHttpService.getNewUsersStatistic(params).pipe(
      tap((newUsersStatistic) => {
        const state = ctx.getState();
        ctx.patchState({
          ...state,
          newUsersStatistic: {
            ...newUsersStatistic,
            data: this.formatTimeByGroup(
              tabMenuItems,
              params.statisticsBy,
              newUsersStatistic.data
            ),
          },
        });
      })
    );
  }

  private calcStatistic(statistic: StatisticResponse): StatisticResponse {
    const fisrtDay = this.formatDate(
      statistic.statisticInFirstDayOfPeriod.date
    );
    const lastDay = this.formatDate(statistic.statisticInLastDayOfPeriod.date);
    return {
      ...statistic,
      statistics: statistic.statistics.map((statisticItem, index) => {
        const date = this.formatDate(statisticItem.time);
        if (date === lastDay) {
          return this.formatStatisticItem(statistic.statisticInLastDayOfPeriod, statisticItem);
        }
        if (date === fisrtDay) {
          return this.formatStatisticItem(statistic.statisticInFirstDayOfPeriod, statisticItem);
        }
        if (!index) {
          this.previousStatistic = this.sumStatistic(
            this.convertStatisticFromStatisticInfo(
              statistic.statisticInFirstDayOfPeriod
            ),
            statisticItem
          );
          return this.previousStatistic;
        }
        this.previousStatistic = this.sumStatistic(
          this.previousStatistic,
          statisticItem
        );
        return this.previousStatistic;
      }),
    };
  }

  private formatTimeByGroup<T extends Statistic | NewUsersStatistic>(
    tabMenuItems: TabMenuItem[],
    statisticsBy: StatisticsBy,
    statistics: T[]
  ): T[] {
    const dateTimeFormat = tabMenuItems.find(
      (i) => i.statisticsBy === statisticsBy
    )?.dateTimeFormat;
    return statistics.map((s) => ({
      ...s,
      time: dateTimeFormat
        ? convertDateWithFormat(s.time, dateTimeFormat)
        : getDateOfISOWeek(s.time),
    }));
  }

  private convertStatisticFromStatisticInfo(
    statisticInfo: StatisticInfo
  ): Statistic {
    return {
      id: "",
      time: "",
      totalAnnuallyPremiumNewUsers: statisticInfo.totalAnnuallyPremiumUsers,
      totalMonthlyPremiumNewUsers: statisticInfo.totalMonthlyPremiumUsers,
      totalNewUsers: statisticInfo.totalUsers,
      totalNormalNewUsers: statisticInfo.totalNormalUsers,
    };
  }

  private sumStatistic(
    oldStatistic: Statistic,
    newStatistic: Statistic
  ): Statistic {
    return {
      ...newStatistic,
      totalAnnuallyPremiumNewUsers:
        newStatistic.totalAnnuallyPremiumNewUsers +
        oldStatistic.totalAnnuallyPremiumNewUsers,
      totalMonthlyPremiumNewUsers:
        newStatistic.totalMonthlyPremiumNewUsers +
        oldStatistic.totalMonthlyPremiumNewUsers,
      totalNewUsers: newStatistic.totalNewUsers + oldStatistic.totalNewUsers,
      totalNormalNewUsers:
        newStatistic.totalNormalNewUsers + oldStatistic.totalNormalNewUsers,
    };
  }

  private formatStatisticItem(
    statisticInfo: StatisticInfo,
    statistic: Statistic
  ): Statistic {
    return {
      ...this.convertStatisticFromStatisticInfo(statisticInfo),
      id: statistic.id,
      time: statistic.time,
    };
  }

  private formatDate(date: string): string {
    return convertDateWithFormat(date, DateTimeFormatEnum.DD_MM_YYYY);
  }
}
