import { GetStatisticsParams } from '@shared/models/http-request.model';

export class GetStatistics {
  static readonly type = '[Statistic] Get statistics';
  constructor(public params: GetStatisticsParams) {}
}

export class GetNewUsersStatistic {
  static readonly type = '[Statistic] Get new users statistic';
  constructor(public params: GetStatisticsParams) {}
}

