import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { CheckboxModule } from 'primeng/checkbox';
import { CoreModule } from '../core/core.module';
import { HeaderComponent } from '../layout/components/header/header.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { InputComponent } from './components/input/input.component';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { StopPropagationClickDirective } from './directives/stop-propagation.directive';
import { LocaleMultiSelectComponent } from './components/locale-multi-select/locale-multi-select.component';
import { EditorModule } from 'primeng/editor';
import { ChipsModule } from 'primeng/chips';
import { UserGroupPickerComponent } from './components/user-group-picker/user-group-picker.component';

const components = [
  HeaderComponent,
  NotFoundComponent,
  LoadingOverlayComponent,
  DropdownComponent,
  InputComponent,
  TextareaComponent,
  PageTitleComponent,
  LocaleMultiSelectComponent,
  UserGroupPickerComponent,
];

const directives = [StopPropagationClickDirective];

const modules = [
  CoreModule,
  ReactiveFormsModule,
  CommonModule,
  HttpClientModule,
  RouterModule,
  RadioButtonModule,
  InputTextModule,
  InputTextareaModule,
  ButtonModule,
  ProgressSpinnerModule,
  ConfirmPopupModule,
  CalendarModule,
  DropdownModule,
  ToastModule,
  TableModule,
  PaginatorModule,
  TabViewModule,
  ChartModule,
  TabMenuModule,
  MultiSelectModule,
  BadgeModule,
  CheckboxModule,
  EditorModule,
  ChipsModule,
];
@NgModule({
  declarations: [...components, ...directives],
  imports: [...modules],
  exports: [...components, ...modules, ...directives],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ConfirmationService],
})
export class SharedModule {}
