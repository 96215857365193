import { NotificationStatusEnum } from "@shared/enums/notification.enum";

export const notificationStatusOptions = [
    {
        value: NotificationStatusEnum.DONE,
        label: 'Done'
    },
    {
        value: NotificationStatusEnum.PENDING,
        label: 'Pending'
    },
    {
        value: NotificationStatusEnum.CANCELLED,
        label: 'Cancelled'
    },
]