import {
  DateTimeFormatEnum,
  StatisticsBy,
  DateRangeInputView,
} from "@shared/enums/common.enum";
import { TabMenuItem } from "@shared/models/tab-menu.model";

export const tabMenuItemsWithoutWeekFormat: TabMenuItem[] = [
  {
    label: "Days",
    dateTimeFormat: DateTimeFormatEnum.DD_MM_YYYY,
    statisticsBy: StatisticsBy.DAY,
    dateRangeInputview: DateRangeInputView.DATE,
  },
  {
    label: "Weeks",
    statisticsBy: StatisticsBy.WEEK,
    dateRangeInputview: DateRangeInputView.DATE,
  },
  {
    label: "Months",
    dateTimeFormat: DateTimeFormatEnum.MM_YYYY,
    statisticsBy: StatisticsBy.MONTH,
    dateRangeInputview: DateRangeInputView.MONTH,
  },
  {
    label: "Years",
    dateTimeFormat: DateTimeFormatEnum.YYYY,
    statisticsBy: StatisticsBy.YEAR,
    dateRangeInputview: DateRangeInputView.YEAR,
  },
];

export const tabMenuItems: TabMenuItem[] = [
  ...tabMenuItemsWithoutWeekFormat,
].map((i) =>
  i.statisticsBy === StatisticsBy.WEEK
    ? { ...i, dateTimeFormat: DateTimeFormatEnum.DD_MM_YYYY }
    : i
);
