import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { appRouteConst } from 'src/app/app-routing.const';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MenuComponent implements OnInit {

  model: MenuItem[] = [];

  constructor(public layoutService: LayoutService) { }

  ngOnInit() {
      this.model = [
          {
            items: [
                { label: 'Dashboard', icon: 'pi pi-chart-bar', routerLink: [`/${appRouteConst.dashboard}`] },
                { label: 'User', icon: 'pi pi-user', routerLink: [`/${appRouteConst.user}`] },
                { label: 'Send email', icon: 'pi pi-inbox', routerLink: [`/${appRouteConst.email}`] },
                { label: 'Send push notification', icon: 'pi pi-bell', routerLink: [`/${appRouteConst.pushNotification}`] },
                { label: 'Announcement', icon: 'pi pi-megaphone', routerLink: [`/${appRouteConst.announcement}`] },
                { label: 'Discount', icon: 'pi pi-percentage', routerLink: [`/${appRouteConst.discount}`] },
            ]
          },
      ];
  }
}
