import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { APP_CONFIG } from 'app.config';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

(async () => {
  const config = environment;
  platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
})();
