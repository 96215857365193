import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageKey } from '@shared/enums/local-storage.enum';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = localStorage.getItem(LocalStorageKey.TOKEN);
    if (token) {
      const headers = new HttpHeaders().append('Authorization', `Bearer ${token}`);
      const modifiedReq = request.clone({
        headers,
      });
      return next.handle(modifiedReq);
    }
    return next.handle(request);
  }
}
