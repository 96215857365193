import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { hasValidationError } from '@shared/utils/validation.utilts';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent {
  @Input()
  public control!: UntypedFormControl;
  @Input()
  public placeholder = '';
  @Input()
  public class = '';

  public hasError = hasValidationError;
}
