import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { AnnouncementHttpService } from '@shared/http/announcement-http.service';
import { Announcement } from '@shared/models/announcement.model';
import { Observable, tap } from 'rxjs';
import {
  FilteringAndSearchingAnnouncements,
  GetAnnouncements,
} from '../actions/announcement.action';
import { IAnnouncementStateModel } from '../state-models/announcement-state.model';
import { filteringAndSearchingWithDateRangeObject } from '@shared/helpers/filtering-and-searching.helper';

@State<IAnnouncementStateModel>({
  name: 'announcementState',
  defaults: {
    baseAnnouncements: [],
    announcements: [],
  },
})
@Injectable()
export class AnnouncementState {
  constructor(private announcementHttpService: AnnouncementHttpService) {}

  @Action(GetAnnouncements) getAnnouncements(
    ctx: StateContext<IAnnouncementStateModel>
  ): Observable<Announcement[]> {
    return this.announcementHttpService.getAnnouncements().pipe(
      tap((baseAnnouncements) => {
        const state = ctx.getState();
        ctx.patchState({
          ...state,
          baseAnnouncements: baseAnnouncements.map(
            (annoucement) => new Announcement(annoucement)
          ),
        });
      })
    );
  }

  @Action(FilteringAndSearchingAnnouncements)
  filteringAndSearchingAnnouncements(
    ctx: StateContext<IAnnouncementStateModel>,
    { params }: FilteringAndSearchingAnnouncements
  ): Announcement[] {
    const state = ctx.getState();
    const announcements = filteringAndSearchingWithDateRangeObject(
      state.baseAnnouncements,
      params
    );
    ctx.patchState({
      ...state,
      announcements,
    });
    return announcements;
  }
}
