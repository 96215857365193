import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { DropdownOption } from '@shared/models/dropdown.model';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit {
  @Input()
  public options: DropdownOption<unknown>[] = [];
  @Input()
  public control!: UntypedFormControl;
  @Input()
  public hasNoneOption = true;
  @Input()
  public class = '';
  @Input()
  public styleClass = '';

  ngOnInit(): void {
    if (this.hasNoneOption && !this.control.hasValidator(Validators.required) && !this.options.some(o => o.value === '')) {
      this.addNoneOption();
    }
  }

  private addNoneOption(): void {
    this.options = [
      ...this.options,
      {
        value: '',
        label: '--',
      },
    ];
  }
}
