<div class="custom-textarea">
    <div class="textarea-container">
      <textarea
        pInputTextarea
        [formControl]="control"
        [placeholder]="placeholder"
        [class]="class"
        [autoResize]="true"
      ></textarea>
    </div>
    <div class="error-messages">
      <small *ngIf="hasError(control, 'required')" class="color-error"
        >This field is required.</small
      >
      <small
        *ngIf="hasError(control, 'email') || hasError(control, 'pattern')"
        class="color-error"
        >Wrong format.</small
      >
    </div>
  </div>
  