import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageKey } from '@shared/enums/local-storage.enum';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { appRouteConst } from 'src/app/app-routing.const';
import { authRouteConst } from 'src/app/modules/auth/auth-routing.const';
import { LayoutService } from '../../services/layout.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  items!: MenuItem[];

  @ViewChild('menubutton') menuButton!: ElementRef;

  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

  @ViewChild('topbarmenu') menu!: ElementRef;

  constructor(
    public layoutService: LayoutService,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {}

  public logout(event: Event): void {
    if (event.target)
      this.confirmationService.confirm({
        target: event.target,
        message: 'Are you sure you want to log out?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          localStorage.removeItem(LocalStorageKey.TOKEN);
          this.router.navigate([
            `/${appRouteConst.auth}/${authRouteConst.login}`,
          ]);
        },
      });
  }
}
