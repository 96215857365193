import { Inject, Injectable } from "@angular/core";
import { User } from '@shared/models/user.model';
import { APP_CONFIG, AppConfig } from "app.config";
import { Observable } from "rxjs";
import { BasicParams } from "../models/http-request.model";
import { IHttpResponse } from "../models/http-response.model";
import { HttpService } from './http.service';


@Injectable({ providedIn: 'root' })
export class UserHttpService {
  private resource: string;

  constructor(
    private http: HttpService,
    @Inject(APP_CONFIG)
    private config: AppConfig,
  ) {
    this.resource = this.config.apiHost;
  }

  public getUsers(params?: BasicParams): Observable<IHttpResponse<User[]>> {
    return this.http
      .get<IHttpResponse<User[]>>(`${this.resource}/users`, params);
  }
}
