<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="">
        <i class="c-icons c-logo mr-2"></i>
        <span>Family Backoffice</span>
    </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <button class="p-link layout-topbar-button">
            <i class="pi pi-user"></i>
            <span>Profile</span>
        </button>
        <p-confirmPopup></p-confirmPopup>
        <button class="p-link layout-topbar-button" (click)="logout($event)">
            <i class="pi pi-sign-out"></i>
            <span>Log out</span>
        </button>
    </div>
</div>