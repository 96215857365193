import { NotificationType } from '@shared/enums/notification.enum';
import {
  FilteringSearchingParams
} from '@shared/models/http-request.model';

export class GetNotifications {
  static readonly type = '[Notification] Get notifications';
  constructor(public notificationType = NotificationType.EMAIL) {}
}
export class FilteringAndSearchingNotifications {
  static readonly type = '[Notification] Filtering and searching notifications';
  constructor(public params?: FilteringSearchingParams) {}
}
