import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { SetLoading } from '../actions/loading.action';
import { ILoadingStateModel } from '../state-models/loading-state.model';

@State<ILoadingStateModel>({
  name: 'loadingState',
  defaults: {
    isLoading: false,
  },
})
@Injectable()
export class LoadingState {

  @Action(SetLoading) setLoading(
    ctx: StateContext<ILoadingStateModel>,
    { payload }: SetLoading
  ): void {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      isLoading: payload,
    });
  }
}
