import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { NotificationHttpService } from '@shared/http/notification-http.service';
import { Notification } from '@shared/models/notification.model';
import { Observable, tap } from 'rxjs';
import { FilteringAndSearchingNotifications, GetNotifications } from '../actions/notification.action';
import { INotificationStateModel } from '../state-models/notification-state.model';
import { filteringAndSearchingNoitfication } from '@shared/helpers/filtering-and-searching.helper';

@State<INotificationStateModel>({
  name: 'notificationState',
  defaults: {
    notifications: [],
    baseNotifications: [],
  },
})
@Injectable()
export class NotificationState {
  constructor(private notificationHttpService: NotificationHttpService) {}

  @Action(GetNotifications) getNotifications(
    ctx: StateContext<INotificationStateModel>,
    { notificationType }: GetNotifications
  ): Observable<Notification[]> {
    return this.notificationHttpService
      .getNotifications(notificationType)
      .pipe(
        tap((notifications) => {
          const state = ctx.getState();
          ctx.patchState({
            ...state,
            baseNotifications: notifications.map(
              (notification) => new Notification(notification)
            ),
          });
        })
      );
  }

  @Action(FilteringAndSearchingNotifications)
  filteringAndSearchingNotifications(
    ctx: StateContext<INotificationStateModel>,
    { params }: FilteringAndSearchingNotifications
  ): Notification[] {
    const state = ctx.getState();
    const notifications = filteringAndSearchingNoitfication(
      state.baseNotifications,
      params
    );
    ctx.patchState({
      ...state,
      notifications,
    });
    return notifications;
  }
}
