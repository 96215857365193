import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalStorageKey } from '@shared/enums/local-storage.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly jwtHelper: JwtHelperService,
  ) {}

  public get isAuthenticated(): boolean {
    const token = localStorage.getItem(LocalStorageKey.TOKEN);
    return !!token && !this.jwtHelper.isTokenExpired(token);
  }
}
