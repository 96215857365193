import { Languages } from '@shared/enums/language.enum';
import { NotificationStatusEnum, NotificationType } from '@shared/enums/notification.enum';
import { PlatformEnum } from '@shared/enums/platform.enum';
import { UserGroupEnum } from '@shared/enums/user.enum';
import { getLocales } from '@shared/helpers/string.helper';

export class Notification {
  id: string;
  title: string;
  to: UserGroupEnum;
  platforms: PlatformEnum[];
  specificEmails: string[];
  exceptEmails: string[];
  scheduledDateTime: string;
  status: NotificationStatusEnum;
  notificationByLocales: NotificationLocale[];
  type: NotificationType;
  locales: string;
  localeKeys?: string[];
  userTimezone: boolean;

  constructor(notification: Notification) {
    this.id = notification.id;
    this.to = notification.to;
    this.platforms = notification.platforms;
    this.specificEmails = notification.specificEmails;
    this.exceptEmails = notification.exceptEmails;
    this.title = getNotificationTitle(notification.notificationByLocales);
    this.scheduledDateTime = notification.scheduledDateTime;
    this.status = notification.status;
    this.userTimezone = notification.userTimezone;
    this.notificationByLocales = notification.notificationByLocales;
    this.type = notification.type || NotificationType.EMAIL;
    this.locales = getLocales(notification.notificationByLocales);
    this.localeKeys = notification.notificationByLocales.map(
      (locale) => locale.langCode
    );
  }
}

const getNotificationTitle = (
  notificationLocales: NotificationLocale[]
): string => {
  const notificationEnLocale = notificationLocales.find(
    (notificationLocale) => notificationLocale.langCode === Languages.EN
  );
  return (notificationEnLocale || notificationLocales[0])?.title;
};

export interface NotificationLocale {
  id: string;
  langCode: Languages;
  title: string;
  content: string;
}
