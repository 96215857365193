import { Status } from '@shared/enums/status.enum';
import { SubscriptionPlan } from '@shared/enums/subscription-plan.enum';
import { getStatus } from '@shared/helpers/status.helper';

export class Discount {
  id: string;
  name: string;
  enabled: boolean;
  discountPercentage: number;
  subscriptionPlan: SubscriptionPlan;
  startDateTime: string;
  endDateTime: string;
  status: Status;

  constructor(discount: Discount) {
    this.id = discount.id;
    this.name = discount.name;
    this.enabled = discount.enabled;
    this.discountPercentage = discount.discountPercentage;
    this.subscriptionPlan = discount.subscriptionPlan;
    this.startDateTime = discount.startDateTime;
    this.endDateTime = discount.endDateTime;
    this.status = getStatus(discount.startDateTime, discount.endDateTime);
  }
}
