import { Status } from '@shared/enums/status.enum';
import dayjs from 'dayjs';

export const getStatus = (startTime: string, endTime: string): Status => {
  if (dayjs().isBefore(startTime)) {
    return Status.PENDING;
  }
  if (dayjs().isAfter(endTime)) {
    return Status.DONE;
  }
  return Status.IN_PROGRESS;
};
